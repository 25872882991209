.materialize-red {
  background-color: #e51c23 !important;
}

.materialize-red-text {
  color: #e51c23 !important;
}

.materialize-red.lighten-5 {
  background-color: #fdeaeb !important;
}

.materialize-red-text.text-lighten-5 {
  color: #fdeaeb !important;
}

.materialize-red.lighten-4 {
  background-color: #f8c1c3 !important;
}

.materialize-red-text.text-lighten-4 {
  color: #f8c1c3 !important;
}

.materialize-red.lighten-3 {
  background-color: #f3989b !important;
}

.materialize-red-text.text-lighten-3 {
  color: #f3989b !important;
}

.materialize-red.lighten-2 {
  background-color: #ee6e73 !important;
}

.materialize-red-text.text-lighten-2 {
  color: #ee6e73 !important;
}

.materialize-red.lighten-1 {
  background-color: #ea454b !important;
}

.materialize-red-text.text-lighten-1 {
  color: #ea454b !important;
}

.materialize-red.darken-1 {
  background-color: #d0181e !important;
}

.materialize-red-text.text-darken-1 {
  color: #d0181e !important;
}

.materialize-red.darken-2 {
  background-color: #b9151b !important;
}

.materialize-red-text.text-darken-2 {
  color: #b9151b !important;
}

.materialize-red.darken-3 {
  background-color: #a21318 !important;
}

.materialize-red-text.text-darken-3 {
  color: #a21318 !important;
}

.materialize-red.darken-4 {
  background-color: #8b1014 !important;
}

.materialize-red-text.text-darken-4 {
  color: #8b1014 !important;
}

.red {
  background-color: #f44336 !important;
}

.red-text {
  color: #f44336 !important;
}

.red.lighten-5 {
  background-color: #ffebee !important;
}

.red-text.text-lighten-5 {
  color: #ffebee !important;
}

.red.lighten-4 {
  background-color: #ffcdd2 !important;
}

.red-text.text-lighten-4 {
  color: #ffcdd2 !important;
}

.red.lighten-3 {
  background-color: #ef9a9a !important;
}

.red-text.text-lighten-3 {
  color: #ef9a9a !important;
}

.red.lighten-2 {
  background-color: #e57373 !important;
}

.red-text.text-lighten-2 {
  color: #e57373 !important;
}

.red.lighten-1 {
  background-color: #ef5350 !important;
}

.red-text.text-lighten-1 {
  color: #ef5350 !important;
}

.red.darken-1 {
  background-color: #e53935 !important;
}

.red-text.text-darken-1 {
  color: #e53935 !important;
}

.red.darken-2 {
  background-color: #d32f2f !important;
}

.red-text.text-darken-2 {
  color: #d32f2f !important;
}

.red.darken-3 {
  background-color: #c62828 !important;
}

.red-text.text-darken-3 {
  color: #c62828 !important;
}

.red.darken-4 {
  background-color: #b71c1c !important;
}

.red-text.text-darken-4 {
  color: #b71c1c !important;
}

.red.accent-1 {
  background-color: #ff8a80 !important;
}

.red-text.text-accent-1 {
  color: #ff8a80 !important;
}

.red.accent-2 {
  background-color: #ff5252 !important;
}

.red-text.text-accent-2 {
  color: #ff5252 !important;
}

.red.accent-3 {
  background-color: #ff1744 !important;
}

.red-text.text-accent-3 {
  color: #ff1744 !important;
}

.red.accent-4 {
  background-color: #d50000 !important;
}

.red-text.text-accent-4 {
  color: #d50000 !important;
}

.pink {
  background-color: #e91e63 !important;
}

.pink-text {
  color: #e91e63 !important;
}

.pink.lighten-5 {
  background-color: #fce4ec !important;
}

.pink-text.text-lighten-5 {
  color: #fce4ec !important;
}

.pink.lighten-4 {
  background-color: #f8bbd0 !important;
}

.pink-text.text-lighten-4 {
  color: #f8bbd0 !important;
}

.pink.lighten-3 {
  background-color: #f48fb1 !important;
}

.pink-text.text-lighten-3 {
  color: #f48fb1 !important;
}

.pink.lighten-2 {
  background-color: #f06292 !important;
}

.pink-text.text-lighten-2 {
  color: #f06292 !important;
}

.pink.lighten-1 {
  background-color: #ec407a !important;
}

.pink-text.text-lighten-1 {
  color: #ec407a !important;
}

.pink.darken-1 {
  background-color: #d81b60 !important;
}

.pink-text.text-darken-1 {
  color: #d81b60 !important;
}

.pink.darken-2 {
  background-color: #c2185b !important;
}

.pink-text.text-darken-2 {
  color: #c2185b !important;
}

.pink.darken-3 {
  background-color: #ad1457 !important;
}

.pink-text.text-darken-3 {
  color: #ad1457 !important;
}

.pink.darken-4 {
  background-color: #880e4f !important;
}

.pink-text.text-darken-4 {
  color: #880e4f !important;
}

.pink.accent-1 {
  background-color: #ff80ab !important;
}

.pink-text.text-accent-1 {
  color: #ff80ab !important;
}

.pink.accent-2 {
  background-color: #ff4081 !important;
}

.pink-text.text-accent-2 {
  color: #ff4081 !important;
}

.pink.accent-3 {
  background-color: #f50057 !important;
}

.pink-text.text-accent-3 {
  color: #f50057 !important;
}

.pink.accent-4 {
  background-color: #c51162 !important;
}

.pink-text.text-accent-4 {
  color: #c51162 !important;
}

.purple {
  background-color: #9c27b0 !important;
}

.purple-text {
  color: #9c27b0 !important;
}

.purple.lighten-5 {
  background-color: #f3e5f5 !important;
}

.purple-text.text-lighten-5 {
  color: #f3e5f5 !important;
}

.purple.lighten-4 {
  background-color: #e1bee7 !important;
}

.purple-text.text-lighten-4 {
  color: #e1bee7 !important;
}

.purple.lighten-3 {
  background-color: #ce93d8 !important;
}

.purple-text.text-lighten-3 {
  color: #ce93d8 !important;
}

.purple.lighten-2 {
  background-color: #ba68c8 !important;
}

.purple-text.text-lighten-2 {
  color: #ba68c8 !important;
}

.purple.lighten-1 {
  background-color: #ab47bc !important;
}

.purple-text.text-lighten-1 {
  color: #ab47bc !important;
}

.purple.darken-1 {
  background-color: #8e24aa !important;
}

.purple-text.text-darken-1 {
  color: #8e24aa !important;
}

.purple.darken-2 {
  background-color: #7b1fa2 !important;
}

.purple-text.text-darken-2 {
  color: #7b1fa2 !important;
}

.purple.darken-3 {
  background-color: #6a1b9a !important;
}

.purple-text.text-darken-3 {
  color: #6a1b9a !important;
}

.purple.darken-4 {
  background-color: #4a148c !important;
}

.purple-text.text-darken-4 {
  color: #4a148c !important;
}

.purple.accent-1 {
  background-color: #ea80fc !important;
}

.purple-text.text-accent-1 {
  color: #ea80fc !important;
}

.purple.accent-2 {
  background-color: #e040fb !important;
}

.purple-text.text-accent-2 {
  color: #e040fb !important;
}

.purple.accent-3 {
  background-color: #d500f9 !important;
}

.purple-text.text-accent-3 {
  color: #d500f9 !important;
}

.purple.accent-4 {
  background-color: #a0f !important;
}

.purple-text.text-accent-4 {
  color: #a0f !important;
}

.deep-purple {
  background-color: #673ab7 !important;
}

.deep-purple-text {
  color: #673ab7 !important;
}

.deep-purple.lighten-5 {
  background-color: #ede7f6 !important;
}

.deep-purple-text.text-lighten-5 {
  color: #ede7f6 !important;
}

.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
}

.deep-purple-text.text-lighten-4 {
  color: #d1c4e9 !important;
}

.deep-purple.lighten-3 {
  background-color: #b39ddb !important;
}

.deep-purple-text.text-lighten-3 {
  color: #b39ddb !important;
}

.deep-purple.lighten-2 {
  background-color: #9575cd !important;
}

.deep-purple-text.text-lighten-2 {
  color: #9575cd !important;
}

.deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
}

.deep-purple-text.text-lighten-1 {
  color: #7e57c2 !important;
}

.deep-purple.darken-1 {
  background-color: #5e35b1 !important;
}

.deep-purple-text.text-darken-1 {
  color: #5e35b1 !important;
}

.deep-purple.darken-2 {
  background-color: #512da8 !important;
}

.deep-purple-text.text-darken-2 {
  color: #512da8 !important;
}

.deep-purple.darken-3 {
  background-color: #4527a0 !important;
}

.deep-purple-text.text-darken-3 {
  color: #4527a0 !important;
}

.deep-purple.darken-4 {
  background-color: #311b92 !important;
}

.deep-purple-text.text-darken-4 {
  color: #311b92 !important;
}

.deep-purple.accent-1 {
  background-color: #b388ff !important;
}

.deep-purple-text.text-accent-1 {
  color: #b388ff !important;
}

.deep-purple.accent-2 {
  background-color: #7c4dff !important;
}

.deep-purple-text.text-accent-2 {
  color: #7c4dff !important;
}

.deep-purple.accent-3 {
  background-color: #651fff !important;
}

.deep-purple-text.text-accent-3 {
  color: #651fff !important;
}

.deep-purple.accent-4 {
  background-color: #6200ea !important;
}

.deep-purple-text.text-accent-4 {
  color: #6200ea !important;
}

.indigo {
  background-color: #3f51b5 !important;
}

.indigo-text {
  color: #3f51b5 !important;
}

.indigo.lighten-5 {
  background-color: #e8eaf6 !important;
}

.indigo-text.text-lighten-5 {
  color: #e8eaf6 !important;
}

.indigo.lighten-4 {
  background-color: #c5cae9 !important;
}

.indigo-text.text-lighten-4 {
  color: #c5cae9 !important;
}

.indigo.lighten-3 {
  background-color: #9fa8da !important;
}

.indigo-text.text-lighten-3 {
  color: #9fa8da !important;
}

.indigo.lighten-2 {
  background-color: #7986cb !important;
}

.indigo-text.text-lighten-2 {
  color: #7986cb !important;
}

.indigo.lighten-1 {
  background-color: #5c6bc0 !important;
}

.indigo-text.text-lighten-1 {
  color: #5c6bc0 !important;
}

.indigo.darken-1 {
  background-color: #3949ab !important;
}

.indigo-text.text-darken-1 {
  color: #3949ab !important;
}

.indigo.darken-2 {
  background-color: #303f9f !important;
}

.indigo-text.text-darken-2 {
  color: #303f9f !important;
}

.indigo.darken-3 {
  background-color: #283593 !important;
}

.indigo-text.text-darken-3 {
  color: #283593 !important;
}

.indigo.darken-4 {
  background-color: #1a237e !important;
}

.indigo-text.text-darken-4 {
  color: #1a237e !important;
}

.indigo.accent-1 {
  background-color: #8c9eff !important;
}

.indigo-text.text-accent-1 {
  color: #8c9eff !important;
}

.indigo.accent-2 {
  background-color: #536dfe !important;
}

.indigo-text.text-accent-2 {
  color: #536dfe !important;
}

.indigo.accent-3 {
  background-color: #3d5afe !important;
}

.indigo-text.text-accent-3 {
  color: #3d5afe !important;
}

.indigo.accent-4 {
  background-color: #304ffe !important;
}

.indigo-text.text-accent-4 {
  color: #304ffe !important;
}

.blue {
  background-color: #2196f3 !important;
}

.blue-text {
  color: #2196f3 !important;
}

.blue.lighten-5 {
  background-color: #e3f2fd !important;
}

.blue-text.text-lighten-5 {
  color: #e3f2fd !important;
}

.blue.lighten-4 {
  background-color: #bbdefb !important;
}

.blue-text.text-lighten-4 {
  color: #bbdefb !important;
}

.blue.lighten-3 {
  background-color: #90caf9 !important;
}

.blue-text.text-lighten-3 {
  color: #90caf9 !important;
}

.blue.lighten-2 {
  background-color: #64b5f6 !important;
}

.blue-text.text-lighten-2 {
  color: #64b5f6 !important;
}

.blue.lighten-1 {
  background-color: #42a5f5 !important;
}

.blue-text.text-lighten-1 {
  color: #42a5f5 !important;
}

.blue.darken-1 {
  background-color: #1e88e5 !important;
}

.blue-text.text-darken-1 {
  color: #1e88e5 !important;
}

.blue.darken-2 {
  background-color: #1976d2 !important;
}

.blue-text.text-darken-2 {
  color: #1976d2 !important;
}

.blue.darken-3 {
  background-color: #1565c0 !important;
}

.blue-text.text-darken-3 {
  color: #1565c0 !important;
}

.blue.darken-4 {
  background-color: #0d47a1 !important;
}

.blue-text.text-darken-4 {
  color: #0d47a1 !important;
}

.blue.accent-1 {
  background-color: #82b1ff !important;
}

.blue-text.text-accent-1 {
  color: #82b1ff !important;
}

.blue.accent-2 {
  background-color: #448aff !important;
}

.blue-text.text-accent-2 {
  color: #448aff !important;
}

.blue.accent-3 {
  background-color: #2979ff !important;
}

.blue-text.text-accent-3 {
  color: #2979ff !important;
}

.blue.accent-4 {
  background-color: #2962ff !important;
}

.blue-text.text-accent-4 {
  color: #2962ff !important;
}

.light-blue {
  background-color: #03a9f4 !important;
}

.light-blue-text {
  color: #03a9f4 !important;
}

.light-blue.lighten-5 {
  background-color: #e1f5fe !important;
}

.light-blue-text.text-lighten-5 {
  color: #e1f5fe !important;
}

.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
}

.light-blue-text.text-lighten-4 {
  color: #b3e5fc !important;
}

.light-blue.lighten-3 {
  background-color: #81d4fa !important;
}

.light-blue-text.text-lighten-3 {
  color: #81d4fa !important;
}

.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
}

.light-blue-text.text-lighten-2 {
  color: #4fc3f7 !important;
}

.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
}

.light-blue-text.text-lighten-1 {
  color: #29b6f6 !important;
}

.light-blue.darken-1 {
  background-color: #039be5 !important;
}

.light-blue-text.text-darken-1 {
  color: #039be5 !important;
}

.light-blue.darken-2 {
  background-color: #0288d1 !important;
}

.light-blue-text.text-darken-2 {
  color: #0288d1 !important;
}

.light-blue.darken-3 {
  background-color: #0277bd !important;
}

.light-blue-text.text-darken-3 {
  color: #0277bd !important;
}

.light-blue.darken-4 {
  background-color: #01579b !important;
}

.light-blue-text.text-darken-4 {
  color: #01579b !important;
}

.light-blue.accent-1 {
  background-color: #80d8ff !important;
}

.light-blue-text.text-accent-1 {
  color: #80d8ff !important;
}

.light-blue.accent-2 {
  background-color: #40c4ff !important;
}

.light-blue-text.text-accent-2 {
  color: #40c4ff !important;
}

.light-blue.accent-3 {
  background-color: #00b0ff !important;
}

.light-blue-text.text-accent-3 {
  color: #00b0ff !important;
}

.light-blue.accent-4 {
  background-color: #0091ea !important;
}

.light-blue-text.text-accent-4 {
  color: #0091ea !important;
}

.cyan {
  background-color: #00bcd4 !important;
}

.cyan-text {
  color: #00bcd4 !important;
}

.cyan.lighten-5 {
  background-color: #e0f7fa !important;
}

.cyan-text.text-lighten-5 {
  color: #e0f7fa !important;
}

.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
}

.cyan-text.text-lighten-4 {
  color: #b2ebf2 !important;
}

.cyan.lighten-3 {
  background-color: #80deea !important;
}

.cyan-text.text-lighten-3 {
  color: #80deea !important;
}

.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
}

.cyan-text.text-lighten-2 {
  color: #4dd0e1 !important;
}

.cyan.lighten-1 {
  background-color: #26c6da !important;
}

.cyan-text.text-lighten-1 {
  color: #26c6da !important;
}

.cyan.darken-1 {
  background-color: #00acc1 !important;
}

.cyan-text.text-darken-1 {
  color: #00acc1 !important;
}

.cyan.darken-2 {
  background-color: #0097a7 !important;
}

.cyan-text.text-darken-2 {
  color: #0097a7 !important;
}

.cyan.darken-3 {
  background-color: #00838f !important;
}

.cyan-text.text-darken-3 {
  color: #00838f !important;
}

.cyan.darken-4 {
  background-color: #006064 !important;
}

.cyan-text.text-darken-4 {
  color: #006064 !important;
}

.cyan.accent-1 {
  background-color: #84ffff !important;
}

.cyan-text.text-accent-1 {
  color: #84ffff !important;
}

.cyan.accent-2 {
  background-color: #18ffff !important;
}

.cyan-text.text-accent-2 {
  color: #18ffff !important;
}

.cyan.accent-3 {
  background-color: #00e5ff !important;
}

.cyan-text.text-accent-3 {
  color: #00e5ff !important;
}

.cyan.accent-4 {
  background-color: #00b8d4 !important;
}

.cyan-text.text-accent-4 {
  color: #00b8d4 !important;
}

.teal {
  background-color: #009688 !important;
}

.teal-text {
  color: #009688 !important;
}

.teal.lighten-5 {
  background-color: #e0f2f1 !important;
}

.teal-text.text-lighten-5 {
  color: #e0f2f1 !important;
}

.teal.lighten-4 {
  background-color: #b2dfdb !important;
}

.teal-text.text-lighten-4 {
  color: #b2dfdb !important;
}

.teal.lighten-3 {
  background-color: #80cbc4 !important;
}

.teal-text.text-lighten-3 {
  color: #80cbc4 !important;
}

.teal.lighten-2 {
  background-color: #4db6ac !important;
}

.teal-text.text-lighten-2 {
  color: #4db6ac !important;
}

.teal.lighten-1 {
  background-color: #26a69a !important;
}

.teal-text.text-lighten-1 {
  color: #26a69a !important;
}

.teal.darken-1 {
  background-color: #00897b !important;
}

.teal-text.text-darken-1 {
  color: #00897b !important;
}

.teal.darken-2 {
  background-color: #00796b !important;
}

.teal-text.text-darken-2 {
  color: #00796b !important;
}

.teal.darken-3 {
  background-color: #00695c !important;
}

.teal-text.text-darken-3 {
  color: #00695c !important;
}

.teal.darken-4 {
  background-color: #004d40 !important;
}

.teal-text.text-darken-4 {
  color: #004d40 !important;
}

.teal.accent-1 {
  background-color: #a7ffeb !important;
}

.teal-text.text-accent-1 {
  color: #a7ffeb !important;
}

.teal.accent-2 {
  background-color: #64ffda !important;
}

.teal-text.text-accent-2 {
  color: #64ffda !important;
}

.teal.accent-3 {
  background-color: #1de9b6 !important;
}

.teal-text.text-accent-3 {
  color: #1de9b6 !important;
}

.teal.accent-4 {
  background-color: #00bfa5 !important;
}

.teal-text.text-accent-4 {
  color: #00bfa5 !important;
}

.green {
  background-color: #4caf50 !important;
}

.green-text {
  color: #4caf50 !important;
}

.green.lighten-5 {
  background-color: #e8f5e9 !important;
}

.green-text.text-lighten-5 {
  color: #e8f5e9 !important;
}

.green.lighten-4 {
  background-color: #c8e6c9 !important;
}

.green-text.text-lighten-4 {
  color: #c8e6c9 !important;
}

.green.lighten-3 {
  background-color: #a5d6a7 !important;
}

.green-text.text-lighten-3 {
  color: #a5d6a7 !important;
}

.green.lighten-2 {
  background-color: #81c784 !important;
}

.green-text.text-lighten-2 {
  color: #81c784 !important;
}

.green.lighten-1 {
  background-color: #66bb6a !important;
}

.green-text.text-lighten-1 {
  color: #66bb6a !important;
}

.green.darken-1 {
  background-color: #43a047 !important;
}

.green-text.text-darken-1 {
  color: #43a047 !important;
}

.green.darken-2 {
  background-color: #388e3c !important;
}

.green-text.text-darken-2 {
  color: #388e3c !important;
}

.green.darken-3 {
  background-color: #2e7d32 !important;
}

.green-text.text-darken-3 {
  color: #2e7d32 !important;
}

.green.darken-4 {
  background-color: #1b5e20 !important;
}

.green-text.text-darken-4 {
  color: #1b5e20 !important;
}

.green.accent-1 {
  background-color: #b9f6ca !important;
}

.green-text.text-accent-1 {
  color: #b9f6ca !important;
}

.green.accent-2 {
  background-color: #69f0ae !important;
}

.green-text.text-accent-2 {
  color: #69f0ae !important;
}

.green.accent-3 {
  background-color: #00e676 !important;
}

.green-text.text-accent-3 {
  color: #00e676 !important;
}

.green.accent-4 {
  background-color: #00c853 !important;
}

.green-text.text-accent-4 {
  color: #00c853 !important;
}

.light-green {
  background-color: #8bc34a !important;
}

.light-green-text {
  color: #8bc34a !important;
}

.light-green.lighten-5 {
  background-color: #f1f8e9 !important;
}

.light-green-text.text-lighten-5 {
  color: #f1f8e9 !important;
}

.light-green.lighten-4 {
  background-color: #dcedc8 !important;
}

.light-green-text.text-lighten-4 {
  color: #dcedc8 !important;
}

.light-green.lighten-3 {
  background-color: #c5e1a5 !important;
}

.light-green-text.text-lighten-3 {
  color: #c5e1a5 !important;
}

.light-green.lighten-2 {
  background-color: #aed581 !important;
}

.light-green-text.text-lighten-2 {
  color: #aed581 !important;
}

.light-green.lighten-1 {
  background-color: #9ccc65 !important;
}

.light-green-text.text-lighten-1 {
  color: #9ccc65 !important;
}

.light-green.darken-1 {
  background-color: #7cb342 !important;
}

.light-green-text.text-darken-1 {
  color: #7cb342 !important;
}

.light-green.darken-2 {
  background-color: #689f38 !important;
}

.light-green-text.text-darken-2 {
  color: #689f38 !important;
}

.light-green.darken-3 {
  background-color: #558b2f !important;
}

.light-green-text.text-darken-3 {
  color: #558b2f !important;
}

.light-green.darken-4 {
  background-color: #33691e !important;
}

.light-green-text.text-darken-4 {
  color: #33691e !important;
}

.light-green.accent-1 {
  background-color: #ccff90 !important;
}

.light-green-text.text-accent-1 {
  color: #ccff90 !important;
}

.light-green.accent-2 {
  background-color: #b2ff59 !important;
}

.light-green-text.text-accent-2 {
  color: #b2ff59 !important;
}

.light-green.accent-3 {
  background-color: #76ff03 !important;
}

.light-green-text.text-accent-3 {
  color: #76ff03 !important;
}

.light-green.accent-4 {
  background-color: #64dd17 !important;
}

.light-green-text.text-accent-4 {
  color: #64dd17 !important;
}

.lime {
  background-color: #cddc39 !important;
}

.lime-text {
  color: #cddc39 !important;
}

.lime.lighten-5 {
  background-color: #f9fbe7 !important;
}

.lime-text.text-lighten-5 {
  color: #f9fbe7 !important;
}

.lime.lighten-4 {
  background-color: #f0f4c3 !important;
}

.lime-text.text-lighten-4 {
  color: #f0f4c3 !important;
}

.lime.lighten-3 {
  background-color: #e6ee9c !important;
}

.lime-text.text-lighten-3 {
  color: #e6ee9c !important;
}

.lime.lighten-2 {
  background-color: #dce775 !important;
}

.lime-text.text-lighten-2 {
  color: #dce775 !important;
}

.lime.lighten-1 {
  background-color: #d4e157 !important;
}

.lime-text.text-lighten-1 {
  color: #d4e157 !important;
}

.lime.darken-1 {
  background-color: #c0ca33 !important;
}

.lime-text.text-darken-1 {
  color: #c0ca33 !important;
}

.lime.darken-2 {
  background-color: #afb42b !important;
}

.lime-text.text-darken-2 {
  color: #afb42b !important;
}

.lime.darken-3 {
  background-color: #9e9d24 !important;
}

.lime-text.text-darken-3 {
  color: #9e9d24 !important;
}

.lime.darken-4 {
  background-color: #827717 !important;
}

.lime-text.text-darken-4 {
  color: #827717 !important;
}

.lime.accent-1 {
  background-color: #f4ff81 !important;
}

.lime-text.text-accent-1 {
  color: #f4ff81 !important;
}

.lime.accent-2 {
  background-color: #eeff41 !important;
}

.lime-text.text-accent-2 {
  color: #eeff41 !important;
}

.lime.accent-3 {
  background-color: #c6ff00 !important;
}

.lime-text.text-accent-3 {
  color: #c6ff00 !important;
}

.lime.accent-4 {
  background-color: #aeea00 !important;
}

.lime-text.text-accent-4 {
  color: #aeea00 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}

.yellow-text {
  color: #ffeb3b !important;
}

.yellow.lighten-5 {
  background-color: #fffde7 !important;
}

.yellow-text.text-lighten-5 {
  color: #fffde7 !important;
}

.yellow.lighten-4 {
  background-color: #fff9c4 !important;
}

.yellow-text.text-lighten-4 {
  color: #fff9c4 !important;
}

.yellow.lighten-3 {
  background-color: #fff59d !important;
}

.yellow-text.text-lighten-3 {
  color: #fff59d !important;
}

.yellow.lighten-2 {
  background-color: #fff176 !important;
}

.yellow-text.text-lighten-2 {
  color: #fff176 !important;
}

.yellow.lighten-1 {
  background-color: #ffee58 !important;
}

.yellow-text.text-lighten-1 {
  color: #ffee58 !important;
}

.yellow.darken-1 {
  background-color: #fdd835 !important;
}

.yellow-text.text-darken-1 {
  color: #fdd835 !important;
}

.yellow.darken-2 {
  background-color: #fbc02d !important;
}

.yellow-text.text-darken-2 {
  color: #fbc02d !important;
}

.yellow.darken-3 {
  background-color: #f9a825 !important;
}

.yellow-text.text-darken-3 {
  color: #f9a825 !important;
}

.yellow.darken-4 {
  background-color: #f57f17 !important;
}

.yellow-text.text-darken-4 {
  color: #f57f17 !important;
}

.yellow.accent-1 {
  background-color: #ffff8d !important;
}

.yellow-text.text-accent-1 {
  color: #ffff8d !important;
}

.yellow.accent-2 {
  background-color: #ff0 !important;
}

.yellow-text.text-accent-2 {
  color: #ff0 !important;
}

.yellow.accent-3 {
  background-color: #ffea00 !important;
}

.yellow-text.text-accent-3 {
  color: #ffea00 !important;
}

.yellow.accent-4 {
  background-color: #ffd600 !important;
}

.yellow-text.text-accent-4 {
  color: #ffd600 !important;
}

.amber {
  background-color: #ffc107 !important;
}

.amber-text {
  color: #ffc107 !important;
}

.amber.lighten-5 {
  background-color: #fff8e1 !important;
}

.amber-text.text-lighten-5 {
  color: #fff8e1 !important;
}

.amber.lighten-4 {
  background-color: #ffecb3 !important;
}

.amber-text.text-lighten-4 {
  color: #ffecb3 !important;
}

.amber.lighten-3 {
  background-color: #ffe082 !important;
}

.amber-text.text-lighten-3 {
  color: #ffe082 !important;
}

.amber.lighten-2 {
  background-color: #ffd54f !important;
}

.amber-text.text-lighten-2 {
  color: #ffd54f !important;
}

.amber.lighten-1 {
  background-color: #ffca28 !important;
}

.amber-text.text-lighten-1 {
  color: #ffca28 !important;
}

.amber.darken-1 {
  background-color: #ffb300 !important;
}

.amber-text.text-darken-1 {
  color: #ffb300 !important;
}

.amber.darken-2 {
  background-color: #ffa000 !important;
}

.amber-text.text-darken-2 {
  color: #ffa000 !important;
}

.amber.darken-3 {
  background-color: #ff8f00 !important;
}

.amber-text.text-darken-3 {
  color: #ff8f00 !important;
}

.amber.darken-4 {
  background-color: #ff6f00 !important;
}

.amber-text.text-darken-4 {
  color: #ff6f00 !important;
}

.amber.accent-1 {
  background-color: #ffe57f !important;
}

.amber-text.text-accent-1 {
  color: #ffe57f !important;
}

.amber.accent-2 {
  background-color: #ffd740 !important;
}

.amber-text.text-accent-2 {
  color: #ffd740 !important;
}

.amber.accent-3 {
  background-color: #ffc400 !important;
}

.amber-text.text-accent-3 {
  color: #ffc400 !important;
}

.amber.accent-4 {
  background-color: #ffab00 !important;
}

.amber-text.text-accent-4 {
  color: #ffab00 !important;
}

.orange {
  background-color: #ff9800 !important;
}

.orange-text {
  color: #ff9800 !important;
}

.orange.lighten-5 {
  background-color: #fff3e0 !important;
}

.orange-text.text-lighten-5 {
  color: #fff3e0 !important;
}

.orange.lighten-4 {
  background-color: #ffe0b2 !important;
}

.orange-text.text-lighten-4 {
  color: #ffe0b2 !important;
}

.orange.lighten-3 {
  background-color: #ffcc80 !important;
}

.orange-text.text-lighten-3 {
  color: #ffcc80 !important;
}

.orange.lighten-2 {
  background-color: #ffb74d !important;
}

.orange-text.text-lighten-2 {
  color: #ffb74d !important;
}

.orange.lighten-1 {
  background-color: #ffa726 !important;
}

.orange-text.text-lighten-1 {
  color: #ffa726 !important;
}

.orange.darken-1 {
  background-color: #fb8c00 !important;
}

.orange-text.text-darken-1 {
  color: #fb8c00 !important;
}

.orange.darken-2 {
  background-color: #f57c00 !important;
}

.orange-text.text-darken-2 {
  color: #f57c00 !important;
}

.orange.darken-3 {
  background-color: #ef6c00 !important;
}

.orange-text.text-darken-3 {
  color: #ef6c00 !important;
}

.orange.darken-4 {
  background-color: #e65100 !important;
}

.orange-text.text-darken-4 {
  color: #e65100 !important;
}

.orange.accent-1 {
  background-color: #ffd180 !important;
}

.orange-text.text-accent-1 {
  color: #ffd180 !important;
}

.orange.accent-2 {
  background-color: #ffab40 !important;
}

.orange-text.text-accent-2 {
  color: #ffab40 !important;
}

.orange.accent-3 {
  background-color: #ff9100 !important;
}

.orange-text.text-accent-3 {
  color: #ff9100 !important;
}

.orange.accent-4 {
  background-color: #ff6d00 !important;
}

.orange-text.text-accent-4 {
  color: #ff6d00 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
}

.deep-orange-text {
  color: #ff5722 !important;
}

.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important;
}

.deep-orange-text.text-lighten-5 {
  color: #fbe9e7 !important;
}

.deep-orange.lighten-4 {
  background-color: #ffccbc !important;
}

.deep-orange-text.text-lighten-4 {
  color: #ffccbc !important;
}

.deep-orange.lighten-3 {
  background-color: #ffab91 !important;
}

.deep-orange-text.text-lighten-3 {
  color: #ffab91 !important;
}

.deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
}

.deep-orange-text.text-lighten-2 {
  color: #ff8a65 !important;
}

.deep-orange.lighten-1 {
  background-color: #ff7043 !important;
}

.deep-orange-text.text-lighten-1 {
  color: #ff7043 !important;
}

.deep-orange.darken-1 {
  background-color: #f4511e !important;
}

.deep-orange-text.text-darken-1 {
  color: #f4511e !important;
}

.deep-orange.darken-2 {
  background-color: #e64a19 !important;
}

.deep-orange-text.text-darken-2 {
  color: #e64a19 !important;
}

.deep-orange.darken-3 {
  background-color: #d84315 !important;
}

.deep-orange-text.text-darken-3 {
  color: #d84315 !important;
}

.deep-orange.darken-4 {
  background-color: #bf360c !important;
}

.deep-orange-text.text-darken-4 {
  color: #bf360c !important;
}

.deep-orange.accent-1 {
  background-color: #ff9e80 !important;
}

.deep-orange-text.text-accent-1 {
  color: #ff9e80 !important;
}

.deep-orange.accent-2 {
  background-color: #ff6e40 !important;
}

.deep-orange-text.text-accent-2 {
  color: #ff6e40 !important;
}

.deep-orange.accent-3 {
  background-color: #ff3d00 !important;
}

.deep-orange-text.text-accent-3 {
  color: #ff3d00 !important;
}

.deep-orange.accent-4 {
  background-color: #dd2c00 !important;
}

.deep-orange-text.text-accent-4 {
  color: #dd2c00 !important;
}

.brown {
  background-color: #795548 !important;
}

.brown-text {
  color: #795548 !important;
}

.brown.lighten-5 {
  background-color: #efebe9 !important;
}

.brown-text.text-lighten-5 {
  color: #efebe9 !important;
}

.brown.lighten-4 {
  background-color: #d7ccc8 !important;
}

.brown-text.text-lighten-4 {
  color: #d7ccc8 !important;
}

.brown.lighten-3 {
  background-color: #bcaaa4 !important;
}

.brown-text.text-lighten-3 {
  color: #bcaaa4 !important;
}

.brown.lighten-2 {
  background-color: #a1887f !important;
}

.brown-text.text-lighten-2 {
  color: #a1887f !important;
}

.brown.lighten-1 {
  background-color: #8d6e63 !important;
}

.brown-text.text-lighten-1 {
  color: #8d6e63 !important;
}

.brown.darken-1 {
  background-color: #6d4c41 !important;
}

.brown-text.text-darken-1 {
  color: #6d4c41 !important;
}

.brown.darken-2 {
  background-color: #5d4037 !important;
}

.brown-text.text-darken-2 {
  color: #5d4037 !important;
}

.brown.darken-3 {
  background-color: #4e342e !important;
}

.brown-text.text-darken-3 {
  color: #4e342e !important;
}

.brown.darken-4 {
  background-color: #3e2723 !important;
}

.brown-text.text-darken-4 {
  color: #3e2723 !important;
}

.blue-grey {
  background-color: #607d8b !important;
}

.blue-grey-text {
  color: #607d8b !important;
}

.blue-grey.lighten-5 {
  background-color: #eceff1 !important;
}

.blue-grey-text.text-lighten-5 {
  color: #eceff1 !important;
}

.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
}

.blue-grey-text.text-lighten-4 {
  color: #cfd8dc !important;
}

.blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
}

.blue-grey-text.text-lighten-3 {
  color: #b0bec5 !important;
}

.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
}

.blue-grey-text.text-lighten-2 {
  color: #90a4ae !important;
}

.blue-grey.lighten-1 {
  background-color: #78909c !important;
}

.blue-grey-text.text-lighten-1 {
  color: #78909c !important;
}

.blue-grey.darken-1 {
  background-color: #546e7a !important;
}

.blue-grey-text.text-darken-1 {
  color: #546e7a !important;
}

.blue-grey.darken-2 {
  background-color: #455a64 !important;
}

.blue-grey-text.text-darken-2 {
  color: #455a64 !important;
}

.blue-grey.darken-3 {
  background-color: #37474f !important;
}

.blue-grey-text.text-darken-3 {
  color: #37474f !important;
}

.blue-grey.darken-4 {
  background-color: #263238 !important;
}

.blue-grey-text.text-darken-4 {
  color: #263238 !important;
}

.grey {
  background-color: #9e9e9e !important;
}

.grey-text {
  color: #9e9e9e !important;
}

.grey.lighten-5 {
  background-color: #fafafa !important;
}

.grey-text.text-lighten-5 {
  color: #fafafa !important;
}

.grey.lighten-4 {
  background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4 {
  color: #f5f5f5 !important;
}

.grey.lighten-3 {
  background-color: #eee !important;
}

.grey-text.text-lighten-3 {
  color: #eee !important;
}

.grey.lighten-2 {
  background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2 {
  color: #e0e0e0 !important;
}

.grey.lighten-1 {
  background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1 {
  color: #bdbdbd !important;
}

.grey.darken-1 {
  background-color: #757575 !important;
}

.grey-text.text-darken-1 {
  color: #757575 !important;
}

.grey.darken-2 {
  background-color: #616161 !important;
}

.grey-text.text-darken-2 {
  color: #616161 !important;
}

.grey.darken-3 {
  background-color: #424242 !important;
}

.grey-text.text-darken-3 {
  color: #424242 !important;
}

.grey.darken-4 {
  background-color: #212121 !important;
}

.grey-text.text-darken-4 {
  color: #212121 !important;
}

.black {
  background-color: #000 !important;
}

.black-text {
  color: #000 !important;
}

.white {
  background-color: #fff !important;
}

.white-text {
  color: #fff !important;
}

.transparent {
  background-color: #0000 !important;
}

.transparent-text {
  color: #0000 !important;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none;
}

ul:not(.browser-default) > li {
  list-style-type: none;
}

a {
  color: #039be5;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

.valign-wrapper {
  align-items: center;
  display: flex;
}

.clearfix {
  clear: both;
}

.z-depth-0 {
  box-shadow: none !important;
}

.z-depth-1, .card, .card-panel, nav {
  box-shadow: 0 2px 2px #00000024, 0 1px 5px #0000001f, 0 3px 1px -2px #0003;
}

.z-depth-1-half {
  box-shadow: 0 3px 3px #00000024, 0 1px 7px #0000001f, 0 3px 1px -1px #0003;
}

.z-depth-2 {
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0000004d;
}

.z-depth-3 {
  box-shadow: 0 6px 10px #00000024, 0 1px 18px #0000001f, 0 3px 5px -1px #0000004d;
}

.z-depth-4 {
  box-shadow: 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f, 0 5px 5px -3px #0000004d;
}

.z-depth-5 {
  box-shadow: 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f, 0 8px 10px -5px #0000004d;
}

.hoverable {
  transition: box-shadow .25s;
}

.hoverable:hover {
  box-shadow: 0 8px 17px #0003, 0 6px 20px #00000030;
}

.divider {
  background-color: #e0e0e0;
  height: 1px;
  overflow: hidden;
}

blockquote {
  border-left: 5px solid #ee6e73;
  margin: 20px 0;
  padding-left: 1.5rem;
}

i {
  line-height: inherit;
}

i.left {
  float: left;
  margin-right: 15px;
}

i.right {
  float: right;
  margin-left: 15px;
}

i.tiny {
  font-size: 1rem;
}

i.small {
  font-size: 2rem;
}

i.medium {
  font-size: 4rem;
}

i.large {
  font-size: 6rem;
}

img.responsive-img, video.responsive-video {
  max-width: 100%;
  height: auto;
}

.pagination li {
  text-align: center;
  vertical-align: top;
  border-radius: 2px;
  height: 30px;
  display: inline-block;
}

.pagination li a {
  color: #444;
  padding: 0 10px;
  font-size: 1.2rem;
  line-height: 30px;
  display: inline-block;
}

.pagination li.active a {
  color: #fff;
}

.pagination li.active {
  background-color: #ee6e73;
}

.pagination li.disabled a {
  cursor: default;
  color: #999;
}

.pagination li i {
  font-size: 2rem;
}

.pagination li.pages ul li {
  float: none;
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  .pagination {
    width: 100%;
  }

  .pagination li.prev, .pagination li.next {
    width: 10%;
  }

  .pagination li.pages {
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
  }
}

.breadcrumb {
  color: #ffffffb3;
  font-size: 18px;
}

.breadcrumb i, .breadcrumb [class^="mdi-"], .breadcrumb [class*="mdi-"], .breadcrumb i.material-icons {
  float: left;
  font-size: 24px;
  display: inline-block;
}

.breadcrumb:before {
  content: "";
  color: #ffffffb3;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  margin: 0 10px 0 8px;
  font-family: Material Icons;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
}

.breadcrumb:first-child:before {
  display: none;
}

.breadcrumb:last-child {
  color: #fff;
}

.parallax-container {
  height: 500px;
  position: relative;
  overflow: hidden;
}

.parallax-container .parallax {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.parallax-container .parallax img {
  min-width: 100%;
  min-height: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.pin-top, .pin-bottom {
  position: relative;
}

.pinned {
  position: fixed !important;
}

ul.staggered-list li {
  opacity: 0;
}

.fade-in {
  opacity: 0;
  transform-origin: 0;
}

@media only screen and (max-width: 600px) {
  .hide-on-small-only, .hide-on-small-and-down {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .hide-on-med-only {
    display: none !important;
  }
}

@media only screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none !important;
  }

  .show-on-large {
    display: block !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .show-on-medium {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .show-on-small {
    display: block !important;
  }
}

@media only screen and (min-width: 601px) {
  .show-on-medium-and-up {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  .show-on-medium-and-down {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .center-on-small-only {
    text-align: center;
  }
}

.page-footer {
  color: #fff;
  background-color: #ee6e73;
  padding-top: 20px;
}

.page-footer .footer-copyright {
  color: #fffc;
  background-color: #33333314;
  align-items: center;
  min-height: 50px;
  padding: 10px 0;
  display: flex;
  overflow: hidden;
}

table, th, td {
  border: none;
}

table {
  width: 100%;
  display: table;
}

table.bordered > thead > tr, table.bordered > tbody > tr {
  border-bottom: 1px solid #d0d0d0;
}

table.striped > tbody > tr:nth-child(odd) {
  background-color: #f2f2f2;
}

table.striped > tbody > tr > td {
  border-radius: 0;
}

table.highlight > tbody > tr {
  transition: background-color .25s;
}

table.highlight > tbody > tr:hover {
  background-color: #f2f2f2;
}

table.centered thead tr th, table.centered tbody tr td {
  text-align: center;
}

thead {
  border-bottom: 1px solid #d0d0d0;
}

td, th {
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
  padding: 15px 5px;
  display: table-cell;
}

@media only screen and (max-width: 992px) {
  table.responsive-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    display: block;
    position: relative;
  }

  table.responsive-table td:empty:before {
    content: " ";
  }

  table.responsive-table th, table.responsive-table td {
    vertical-align: top;
    margin: 0;
  }

  table.responsive-table th {
    text-align: left;
  }

  table.responsive-table thead {
    float: left;
    display: block;
  }

  table.responsive-table thead tr {
    padding: 0 10px 0 0;
    display: block;
  }

  table.responsive-table thead tr th:before {
    content: " ";
  }

  table.responsive-table tbody {
    white-space: nowrap;
    width: auto;
    display: block;
    position: relative;
    overflow-x: auto;
  }

  table.responsive-table tbody tr {
    vertical-align: top;
    display: inline-block;
  }

  table.responsive-table th {
    text-align: right;
    display: block;
  }

  table.responsive-table td {
    text-align: left;
    min-height: 1.25em;
    display: block;
  }

  table.responsive-table tr {
    padding: 0 10px;
  }

  table.responsive-table thead {
    border: 0;
    border-right: 1px solid #d0d0d0;
  }

  table.responsive-table.bordered th {
    border-bottom: 0;
    border-left: 0;
  }

  table.responsive-table.bordered td {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }

  table.responsive-table.bordered tr {
    border: 0;
  }

  table.responsive-table.bordered tbody tr {
    border-right: 1px solid #d0d0d0;
  }
}

.collection {
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  margin: .5rem 0 1rem;
  position: relative;
  overflow: hidden;
}

.collection .collection-item {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
  padding: 10px 20px;
  line-height: 1.5rem;
}

.collection .collection-item.avatar {
  min-height: 84px;
  padding-left: 72px;
  position: relative;
}

.collection .collection-item.avatar:not(.circle-clipper) > .circle, .collection .collection-item.avatar :not(.circle-clipper) > .circle {
  vertical-align: middle;
  width: 42px;
  height: 42px;
  display: inline-block;
  position: absolute;
  left: 15px;
  overflow: hidden;
}

.collection .collection-item.avatar i.circle {
  color: #fff;
  text-align: center;
  background-color: #999;
  font-size: 18px;
  line-height: 42px;
}

.collection .collection-item.avatar .title {
  font-size: 16px;
}

.collection .collection-item.avatar p {
  margin: 0;
}

.collection .collection-item.avatar .secondary-content {
  position: absolute;
  top: 16px;
  right: 16px;
}

.collection .collection-item:last-child {
  border-bottom: none;
}

.collection .collection-item.active {
  color: #eafaf9;
  background-color: #26a69a;
}

.collection .collection-item.active .secondary-content {
  color: #fff;
}

.collection a.collection-item {
  color: #26a69a;
  transition: all .25s;
  display: block;
}

.collection a.collection-item:not(.active):hover {
  background-color: #ddd;
}

.collection.with-header .collection-header {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
}

.collection.with-header .collection-item {
  padding-left: 30px;
}

.collection.with-header .collection-item.avatar {
  padding-left: 72px;
}

.secondary-content {
  float: right;
  color: #26a69a;
}

.collapsible .collection {
  border: none;
  margin: 0;
}

.video-container {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.progress {
  background-color: #acece6;
  border-radius: 2px;
  width: 100%;
  height: 4px;
  margin: .5rem 0 1rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.progress .determinate {
  background-color: #26a69a;
  transition: width .3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.progress .indeterminate {
  background-color: #26a69a;
}

.progress .indeterminate:before {
  content: "";
  background-color: inherit;
  will-change: left, right;
  animation: 2.1s cubic-bezier(.65, .815, .735, .395) infinite indeterminate;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.progress .indeterminate:after {
  content: "";
  background-color: inherit;
  will-change: left, right;
  animation: 2.1s cubic-bezier(.165, .84, .44, 1) 1.15s infinite indeterminate-short;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.hide {
  display: none !important;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center, .center-align {
  text-align: center;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.circle {
  border-radius: 50%;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.no-padding {
  padding: 0 !important;
}

.container {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}

.container .row {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.section.no-pad {
  padding: 0;
}

.section.no-pad-bot {
  padding-bottom: 0;
}

.section.no-pad-top {
  padding-top: 0;
}

.row {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row .col {
  float: left;
  box-sizing: border-box;
  min-height: 1px;
  padding: 0 .75rem;
}

.row .col[class*="push-"], .row .col[class*="pull-"] {
  position: relative;
}

.row .col.s1 {
  width: 8.33333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s2 {
  width: 16.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s4 {
  width: 33.3333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5 {
  width: 41.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s7 {
  width: 58.3333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s8 {
  width: 66.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s10 {
  width: 83.3333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s11 {
  width: 91.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.offset-s1 {
  margin-left: 8.33333%;
}

.row .col.pull-s1 {
  right: 8.33333%;
}

.row .col.push-s1 {
  left: 8.33333%;
}

.row .col.offset-s2 {
  margin-left: 16.6667%;
}

.row .col.pull-s2 {
  right: 16.6667%;
}

.row .col.push-s2 {
  left: 16.6667%;
}

.row .col.offset-s3 {
  margin-left: 25%;
}

.row .col.pull-s3 {
  right: 25%;
}

.row .col.push-s3 {
  left: 25%;
}

.row .col.offset-s4 {
  margin-left: 33.3333%;
}

.row .col.pull-s4 {
  right: 33.3333%;
}

.row .col.push-s4 {
  left: 33.3333%;
}

.row .col.offset-s5 {
  margin-left: 41.6667%;
}

.row .col.pull-s5 {
  right: 41.6667%;
}

.row .col.push-s5 {
  left: 41.6667%;
}

.row .col.offset-s6 {
  margin-left: 50%;
}

.row .col.pull-s6 {
  right: 50%;
}

.row .col.push-s6 {
  left: 50%;
}

.row .col.offset-s7 {
  margin-left: 58.3333%;
}

.row .col.pull-s7 {
  right: 58.3333%;
}

.row .col.push-s7 {
  left: 58.3333%;
}

.row .col.offset-s8 {
  margin-left: 66.6667%;
}

.row .col.pull-s8 {
  right: 66.6667%;
}

.row .col.push-s8 {
  left: 66.6667%;
}

.row .col.offset-s9 {
  margin-left: 75%;
}

.row .col.pull-s9 {
  right: 75%;
}

.row .col.push-s9 {
  left: 75%;
}

.row .col.offset-s10 {
  margin-left: 83.3333%;
}

.row .col.pull-s10 {
  right: 83.3333%;
}

.row .col.push-s10 {
  left: 83.3333%;
}

.row .col.offset-s11 {
  margin-left: 91.6667%;
}

.row .col.pull-s11 {
  right: 91.6667%;
}

.row .col.push-s11 {
  left: 91.6667%;
}

.row .col.offset-s12 {
  margin-left: 100%;
}

.row .col.pull-s12 {
  right: 100%;
}

.row .col.push-s12 {
  left: 100%;
}

@media only screen and (min-width: 601px) {
  .row .col.m1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m2 {
    width: 16.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m4 {
    width: 33.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m5 {
    width: 41.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m7 {
    width: 58.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m8 {
    width: 66.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m10 {
    width: 83.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m11 {
    width: 91.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.offset-m1 {
    margin-left: 8.33333%;
  }

  .row .col.pull-m1 {
    right: 8.33333%;
  }

  .row .col.push-m1 {
    left: 8.33333%;
  }

  .row .col.offset-m2 {
    margin-left: 16.6667%;
  }

  .row .col.pull-m2 {
    right: 16.6667%;
  }

  .row .col.push-m2 {
    left: 16.6667%;
  }

  .row .col.offset-m3 {
    margin-left: 25%;
  }

  .row .col.pull-m3 {
    right: 25%;
  }

  .row .col.push-m3 {
    left: 25%;
  }

  .row .col.offset-m4 {
    margin-left: 33.3333%;
  }

  .row .col.pull-m4 {
    right: 33.3333%;
  }

  .row .col.push-m4 {
    left: 33.3333%;
  }

  .row .col.offset-m5 {
    margin-left: 41.6667%;
  }

  .row .col.pull-m5 {
    right: 41.6667%;
  }

  .row .col.push-m5 {
    left: 41.6667%;
  }

  .row .col.offset-m6 {
    margin-left: 50%;
  }

  .row .col.pull-m6 {
    right: 50%;
  }

  .row .col.push-m6 {
    left: 50%;
  }

  .row .col.offset-m7 {
    margin-left: 58.3333%;
  }

  .row .col.pull-m7 {
    right: 58.3333%;
  }

  .row .col.push-m7 {
    left: 58.3333%;
  }

  .row .col.offset-m8 {
    margin-left: 66.6667%;
  }

  .row .col.pull-m8 {
    right: 66.6667%;
  }

  .row .col.push-m8 {
    left: 66.6667%;
  }

  .row .col.offset-m9 {
    margin-left: 75%;
  }

  .row .col.pull-m9 {
    right: 75%;
  }

  .row .col.push-m9 {
    left: 75%;
  }

  .row .col.offset-m10 {
    margin-left: 83.3333%;
  }

  .row .col.pull-m10 {
    right: 83.3333%;
  }

  .row .col.push-m10 {
    left: 83.3333%;
  }

  .row .col.offset-m11 {
    margin-left: 91.6667%;
  }

  .row .col.pull-m11 {
    right: 91.6667%;
  }

  .row .col.push-m11 {
    left: 91.6667%;
  }

  .row .col.offset-m12 {
    margin-left: 100%;
  }

  .row .col.pull-m12 {
    right: 100%;
  }

  .row .col.push-m12 {
    left: 100%;
  }
}

@media only screen and (min-width: 993px) {
  .row .col.l1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l2 {
    width: 16.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l4 {
    width: 33.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l5 {
    width: 41.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l7 {
    width: 58.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l8 {
    width: 66.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l10 {
    width: 83.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l11 {
    width: 91.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.offset-l1 {
    margin-left: 8.33333%;
  }

  .row .col.pull-l1 {
    right: 8.33333%;
  }

  .row .col.push-l1 {
    left: 8.33333%;
  }

  .row .col.offset-l2 {
    margin-left: 16.6667%;
  }

  .row .col.pull-l2 {
    right: 16.6667%;
  }

  .row .col.push-l2 {
    left: 16.6667%;
  }

  .row .col.offset-l3 {
    margin-left: 25%;
  }

  .row .col.pull-l3 {
    right: 25%;
  }

  .row .col.push-l3 {
    left: 25%;
  }

  .row .col.offset-l4 {
    margin-left: 33.3333%;
  }

  .row .col.pull-l4 {
    right: 33.3333%;
  }

  .row .col.push-l4 {
    left: 33.3333%;
  }

  .row .col.offset-l5 {
    margin-left: 41.6667%;
  }

  .row .col.pull-l5 {
    right: 41.6667%;
  }

  .row .col.push-l5 {
    left: 41.6667%;
  }

  .row .col.offset-l6 {
    margin-left: 50%;
  }

  .row .col.pull-l6 {
    right: 50%;
  }

  .row .col.push-l6 {
    left: 50%;
  }

  .row .col.offset-l7 {
    margin-left: 58.3333%;
  }

  .row .col.pull-l7 {
    right: 58.3333%;
  }

  .row .col.push-l7 {
    left: 58.3333%;
  }

  .row .col.offset-l8 {
    margin-left: 66.6667%;
  }

  .row .col.pull-l8 {
    right: 66.6667%;
  }

  .row .col.push-l8 {
    left: 66.6667%;
  }

  .row .col.offset-l9 {
    margin-left: 75%;
  }

  .row .col.pull-l9 {
    right: 75%;
  }

  .row .col.push-l9 {
    left: 75%;
  }

  .row .col.offset-l10 {
    margin-left: 83.3333%;
  }

  .row .col.pull-l10 {
    right: 83.3333%;
  }

  .row .col.push-l10 {
    left: 83.3333%;
  }

  .row .col.offset-l11 {
    margin-left: 91.6667%;
  }

  .row .col.pull-l11 {
    right: 91.6667%;
  }

  .row .col.push-l11 {
    left: 91.6667%;
  }

  .row .col.offset-l12 {
    margin-left: 100%;
  }

  .row .col.pull-l12 {
    right: 100%;
  }

  .row .col.push-l12 {
    left: 100%;
  }
}

@media only screen and (min-width: 1201px) {
  .row .col.xl1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl2 {
    width: 16.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl4 {
    width: 33.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl5 {
    width: 41.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl7 {
    width: 58.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl8 {
    width: 66.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl10 {
    width: 83.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl11 {
    width: 91.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.offset-xl1 {
    margin-left: 8.33333%;
  }

  .row .col.pull-xl1 {
    right: 8.33333%;
  }

  .row .col.push-xl1 {
    left: 8.33333%;
  }

  .row .col.offset-xl2 {
    margin-left: 16.6667%;
  }

  .row .col.pull-xl2 {
    right: 16.6667%;
  }

  .row .col.push-xl2 {
    left: 16.6667%;
  }

  .row .col.offset-xl3 {
    margin-left: 25%;
  }

  .row .col.pull-xl3 {
    right: 25%;
  }

  .row .col.push-xl3 {
    left: 25%;
  }

  .row .col.offset-xl4 {
    margin-left: 33.3333%;
  }

  .row .col.pull-xl4 {
    right: 33.3333%;
  }

  .row .col.push-xl4 {
    left: 33.3333%;
  }

  .row .col.offset-xl5 {
    margin-left: 41.6667%;
  }

  .row .col.pull-xl5 {
    right: 41.6667%;
  }

  .row .col.push-xl5 {
    left: 41.6667%;
  }

  .row .col.offset-xl6 {
    margin-left: 50%;
  }

  .row .col.pull-xl6 {
    right: 50%;
  }

  .row .col.push-xl6 {
    left: 50%;
  }

  .row .col.offset-xl7 {
    margin-left: 58.3333%;
  }

  .row .col.pull-xl7 {
    right: 58.3333%;
  }

  .row .col.push-xl7 {
    left: 58.3333%;
  }

  .row .col.offset-xl8 {
    margin-left: 66.6667%;
  }

  .row .col.pull-xl8 {
    right: 66.6667%;
  }

  .row .col.push-xl8 {
    left: 66.6667%;
  }

  .row .col.offset-xl9 {
    margin-left: 75%;
  }

  .row .col.pull-xl9 {
    right: 75%;
  }

  .row .col.push-xl9 {
    left: 75%;
  }

  .row .col.offset-xl10 {
    margin-left: 83.3333%;
  }

  .row .col.pull-xl10 {
    right: 83.3333%;
  }

  .row .col.push-xl10 {
    left: 83.3333%;
  }

  .row .col.offset-xl11 {
    margin-left: 91.6667%;
  }

  .row .col.pull-xl11 {
    right: 91.6667%;
  }

  .row .col.push-xl11 {
    left: 91.6667%;
  }

  .row .col.offset-xl12 {
    margin-left: 100%;
  }

  .row .col.pull-xl12 {
    right: 100%;
  }

  .row .col.push-xl12 {
    left: 100%;
  }
}

nav {
  color: #fff;
  background-color: #ee6e73;
  width: 100%;
  height: 56px;
  line-height: 56px;
}

nav.nav-extended {
  height: auto;
}

nav.nav-extended .nav-wrapper {
  height: auto;
  min-height: 56px;
}

nav.nav-extended .nav-content {
  line-height: normal;
  position: relative;
}

nav a {
  color: #fff;
}

nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons {
  height: 56px;
  font-size: 24px;
  line-height: 56px;
  display: block;
}

nav .nav-wrapper {
  height: 100%;
  position: relative;
}

@media only screen and (min-width: 993px) {
  nav a.button-collapse {
    display: none;
  }
}

nav .button-collapse {
  float: left;
  z-index: 1;
  height: 56px;
  margin: 0 18px;
  position: relative;
}

nav .button-collapse i {
  height: 56px;
  line-height: 56px;
}

nav .brand-logo {
  color: #fff;
  padding: 0;
  font-size: 2.1rem;
  display: inline-block;
  position: absolute;
}

nav .brand-logo.center {
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 992px) {
  nav .brand-logo {
    left: 50%;
    transform: translateX(-50%);
  }

  nav .brand-logo.left, nav .brand-logo.right {
    padding: 0;
    transform: none;
  }

  nav .brand-logo.left {
    left: .5rem;
  }

  nav .brand-logo.right {
    left: auto;
    right: .5rem;
  }
}

nav .brand-logo.right {
  padding: 0;
  right: .5rem;
}

nav .brand-logo i, nav .brand-logo [class^="mdi-"], nav .brand-logo [class*="mdi-"], nav .brand-logo i.material-icons {
  float: left;
  margin-right: 15px;
}

nav .nav-title {
  padding: 28px 0;
  font-size: 32px;
  display: inline-block;
}

nav ul {
  margin: 0;
}

nav ul li {
  float: left;
  padding: 0;
  transition: background-color .3s;
}

nav ul li.active {
  background-color: #0000001a;
}

nav ul a {
  color: #fff;
  cursor: pointer;
  padding: 0 15px;
  font-size: 1rem;
  transition: background-color .3s;
  display: block;
}

nav ul a.btn, nav ul a.btn-large, nav ul a.btn-flat, nav ul a.btn-floating {
  margin-top: -2px;
  margin-left: 15px;
  margin-right: 15px;
}

nav ul a.btn > .material-icons, nav ul a.btn-large > .material-icons, nav ul a.btn-flat > .material-icons, nav ul a.btn-floating > .material-icons {
  height: inherit;
  line-height: inherit;
}

nav ul a:hover {
  background-color: #0000001a;
}

nav ul.left {
  float: left;
}

nav form {
  height: 100%;
}

nav .input-field {
  height: 100%;
  margin: 0;
}

nav .input-field input {
  border: none;
  height: 100%;
  padding-left: 2rem;
  font-size: 1.2rem;
}

nav .input-field input:focus, nav .input-field input[type="text"]:valid, nav .input-field input[type="password"]:valid, nav .input-field input[type="email"]:valid, nav .input-field input[type="url"]:valid, nav .input-field input[type="date"]:valid {
  box-shadow: none;
  border: none;
}

nav .input-field label {
  top: 0;
  left: 0;
}

nav .input-field label i {
  color: #ffffffb3;
  transition: color .3s;
}

nav .input-field label.active i {
  color: #fff;
}

.navbar-fixed {
  z-index: 997;
  height: 56px;
  position: relative;
}

.navbar-fixed nav {
  position: fixed;
}

@media only screen and (min-width: 601px) {
  nav.nav-extended .nav-wrapper {
    min-height: 64px;
  }

  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
    height: 64px;
    line-height: 64px;
  }

  .navbar-fixed {
    height: 64px;
  }
}

a {
  text-decoration: none;
}

html {
  color: #000000de;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  line-height: 1.5;
}

@media only screen and (min-width: 0) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 14.5px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.1;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
}

h1 {
  margin: 2.1rem 0 1.68rem;
  font-size: 4.2rem;
  line-height: 110%;
}

h2 {
  margin: 1.78rem 0 1.424rem;
  font-size: 3.56rem;
  line-height: 110%;
}

h3 {
  margin: 1.46rem 0 1.168rem;
  font-size: 2.92rem;
  line-height: 110%;
}

h4 {
  margin: 1.14rem 0 .912rem;
  font-size: 2.28rem;
  line-height: 110%;
}

h5 {
  margin: .82rem 0 .656rem;
  font-size: 1.64rem;
  line-height: 110%;
}

h6 {
  margin: .5rem 0 .4rem;
  font-size: 1rem;
  line-height: 110%;
}

em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

small {
  font-size: 75%;
}

.light, .page-footer .footer-copyright {
  font-weight: 300;
}

.thin {
  font-weight: 200;
}

.flow-text {
  font-weight: 300;
}

@media only screen and (min-width: 360px) {
  .flow-text {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 390px) {
  .flow-text {
    font-size: 1.224rem;
  }
}

@media only screen and (min-width: 420px) {
  .flow-text {
    font-size: 1.248rem;
  }
}

@media only screen and (min-width: 450px) {
  .flow-text {
    font-size: 1.272rem;
  }
}

@media only screen and (min-width: 480px) {
  .flow-text {
    font-size: 1.296rem;
  }
}

@media only screen and (min-width: 510px) {
  .flow-text {
    font-size: 1.32rem;
  }
}

@media only screen and (min-width: 540px) {
  .flow-text {
    font-size: 1.344rem;
  }
}

@media only screen and (min-width: 570px) {
  .flow-text {
    font-size: 1.368rem;
  }
}

@media only screen and (min-width: 600px) {
  .flow-text {
    font-size: 1.392rem;
  }
}

@media only screen and (min-width: 630px) {
  .flow-text {
    font-size: 1.416rem;
  }
}

@media only screen and (min-width: 660px) {
  .flow-text {
    font-size: 1.44rem;
  }
}

@media only screen and (min-width: 690px) {
  .flow-text {
    font-size: 1.464rem;
  }
}

@media only screen and (min-width: 720px) {
  .flow-text {
    font-size: 1.488rem;
  }
}

@media only screen and (min-width: 750px) {
  .flow-text {
    font-size: 1.512rem;
  }
}

@media only screen and (min-width: 780px) {
  .flow-text {
    font-size: 1.536rem;
  }
}

@media only screen and (min-width: 810px) {
  .flow-text {
    font-size: 1.56rem;
  }
}

@media only screen and (min-width: 840px) {
  .flow-text {
    font-size: 1.584rem;
  }
}

@media only screen and (min-width: 870px) {
  .flow-text {
    font-size: 1.608rem;
  }
}

@media only screen and (min-width: 900px) {
  .flow-text {
    font-size: 1.632rem;
  }
}

@media only screen and (min-width: 930px) {
  .flow-text {
    font-size: 1.656rem;
  }
}

@media only screen and (min-width: 960px) {
  .flow-text {
    font-size: 1.68rem;
  }
}

@media only screen and (max-width: 360px) {
  .flow-text {
    font-size: 1.2rem;
  }
}

.card-panel {
  background-color: #fff;
  border-radius: 2px;
  margin: .5rem 0 1rem;
  padding: 24px;
  transition: box-shadow .25s;
}

.card {
  background-color: #fff;
  border-radius: 2px;
  margin: .5rem 0 1rem;
  transition: box-shadow .25s;
  position: relative;
}

.card .card-title {
  font-size: 24px;
  font-weight: 300;
}

.card .card-title.activator {
  cursor: pointer;
}

.card.small, .card.medium, .card.large {
  position: relative;
}

.card.small .card-image, .card.medium .card-image, .card.large .card-image {
  max-height: 60%;
  overflow: hidden;
}

.card.small .card-image + .card-content, .card.medium .card-image + .card-content, .card.large .card-image + .card-content {
  max-height: 40%;
}

.card.small .card-content, .card.medium .card-content, .card.large .card-content {
  max-height: 100%;
  overflow: hidden;
}

.card.small .card-action, .card.medium .card-action, .card.large .card-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card.small {
  height: 300px;
}

.card.medium {
  height: 400px;
}

.card.large {
  height: 500px;
}

.card.horizontal {
  display: flex;
}

.card.horizontal.small .card-image, .card.horizontal.medium .card-image, .card.horizontal.large .card-image {
  height: 100%;
  max-height: none;
  overflow: visible;
}

.card.horizontal.small .card-image img, .card.horizontal.medium .card-image img, .card.horizontal.large .card-image img {
  height: 100%;
}

.card.horizontal .card-image {
  max-width: 50%;
}

.card.horizontal .card-image img {
  border-radius: 2px 0 0 2px;
  width: auto;
  max-width: 100%;
}

.card.horizontal .card-stacked {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.card.horizontal .card-stacked .card-content {
  flex-grow: 1;
}

.card.sticky-action .card-action {
  z-index: 2;
}

.card.sticky-action .card-reveal {
  z-index: 1;
  padding-bottom: 64px;
}

.card .card-image {
  position: relative;
}

.card .card-image img {
  border-radius: 2px 2px 0 0;
  width: 100%;
  display: block;
  position: relative;
  inset: 0;
}

.card .card-image .card-title {
  color: #fff;
  max-width: 100%;
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card .card-content {
  border-radius: 0 0 2px 2px;
  padding: 24px;
}

.card .card-content p {
  color: inherit;
  margin: 0;
}

.card .card-content .card-title {
  margin-bottom: 8px;
  line-height: 32px;
  display: block;
}

.card .card-content .card-title i {
  line-height: 32px;
}

.card .card-action {
  background-color: inherit;
  border-top: 1px solid #a0a0a033;
  padding: 16px 24px;
  position: relative;
}

.card .card-action:last-child {
  border-radius: 0 0 2px 2px;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-floating) {
  color: #ffab40;
  text-transform: uppercase;
  margin-right: 24px;
  transition: color .3s;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-floating):hover {
  color: #ffd8a6;
}

.card .card-reveal {
  z-index: 3;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
}

.card .card-reveal .card-title {
  cursor: pointer;
  display: block;
}

.switch, .switch * {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.switch label {
  cursor: pointer;
}

.switch label input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: #84c7c1;
}

.switch label input[type="checkbox"]:checked + .lever:before, .switch label input[type="checkbox"]:checked + .lever:after {
  left: 18px;
}

.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #26a69a;
}

.switch label .lever {
  content: "";
  vertical-align: middle;
  background-color: #00000061;
  border-radius: 15px;
  width: 36px;
  height: 14px;
  margin: 0 16px;
  transition: background .3s;
  display: inline-block;
  position: relative;
}

.switch label .lever:before, .switch label .lever:after {
  content: "";
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: left .3s, background .3s, box-shadow .1s, transform .1s;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 0;
}

.switch label .lever:before {
  background-color: #26a69a26;
}

.switch label .lever:after {
  background-color: #f1f1f1;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

input[type="checkbox"]:checked:not(:disabled) ~ .lever:active:before, input[type="checkbox"]:checked:not(:disabled).tabbed:focus ~ .lever:before {
  background-color: #26a69a26;
  transform: scale(2.4);
}

input[type="checkbox"]:not(:disabled) ~ .lever:active:before, input[type="checkbox"]:not(:disabled).tabbed:focus ~ .lever:before {
  background-color: #00000014;
  transform: scale(2.4);
}

.switch input[type="checkbox"][disabled] + .lever {
  cursor: default;
  background-color: #0000001f;
}

.switch label input[type="checkbox"][disabled] + .lever:after, .switch label input[type="checkbox"][disabled]:checked + .lever:after {
  background-color: #949494;
}

.preloader-wrapper {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
}

.preloader-wrapper.small {
  width: 36px;
  height: 36px;
}

.preloader-wrapper.big {
  width: 64px;
  height: 64px;
}

.preloader-wrapper.active {
  animation: 1.568s linear infinite container-rotate;
}

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}

.spinner-layer {
  opacity: 0;
  border-color: #26a69a;
  width: 100%;
  height: 100%;
  position: absolute;
}

.spinner-blue, .spinner-blue-only {
  border-color: #4285f4;
}

.spinner-red, .spinner-red-only {
  border-color: #db4437;
}

.spinner-yellow, .spinner-yellow-only {
  border-color: #f4b400;
}

.spinner-green, .spinner-green-only {
  border-color: #0f9d58;
}

.active .spinner-layer.spinner-blue {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both blue-fade-in-out;
}

.active .spinner-layer.spinner-red {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both red-fade-in-out;
}

.active .spinner-layer.spinner-yellow {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both yellow-fade-in-out;
}

.active .spinner-layer.spinner-green {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both green-fade-in-out;
}

.active .spinner-layer, .active .spinner-layer.spinner-blue-only, .active .spinner-layer.spinner-red-only, .active .spinner-layer.spinner-yellow-only, .active .spinner-layer.spinner-green-only {
  opacity: 1;
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both fill-unfill-rotate;
}

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
  }

  25% {
    -webkit-transform: rotate(270deg);
  }

  37.5% {
    -webkit-transform: rotate(405deg);
  }

  50% {
    -webkit-transform: rotate(540deg);
  }

  62.5% {
    -webkit-transform: rotate(675deg);
  }

  75% {
    -webkit-transform: rotate(810deg);
  }

  87.5% {
    -webkit-transform: rotate(945deg);
  }

  to {
    -webkit-transform: rotate(1080deg);
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  to {
    transform: rotate(1080deg);
  }
}

@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  26% {
    opacity: 0;
  }

  89% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }
}

@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  76% {
    opacity: 0;
  }
}

@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.gap-patch {
  border-color: inherit;
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 45%;
  overflow: hidden;
}

.gap-patch .circle {
  width: 1000%;
  left: -450%;
}

.circle-clipper {
  border-color: inherit;
  width: 50%;
  height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.circle-clipper .circle {
  border-style: solid;
  border-width: 3px;
  border-color: inherit;
  border-radius: 50%;
  width: 200%;
  height: 100%;
  animation: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-bottom-color: #0000 !important;
}

.circle-clipper.left .circle {
  left: 0;
  transform: rotate(129deg);
  border-right-color: #0000 !important;
}

.circle-clipper.right .circle {
  left: -100%;
  transform: rotate(-129deg);
  border-left-color: #0000 !important;
}

.active .circle-clipper.left .circle {
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both left-spin;
}

.active .circle-clipper.right .circle {
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both right-spin;
}

@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
  }

  50% {
    -webkit-transform: rotate(-5deg);
  }

  to {
    -webkit-transform: rotate(130deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(130deg);
  }
}

@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
  }

  50% {
    -webkit-transform: rotate(5deg);
  }

  to {
    -webkit-transform: rotate(-130deg);
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-130deg);
  }
}

#spinnerContainer.cooldown {
  animation: 1.568s linear infinite container-rotate, .4s cubic-bezier(.4, 0, .2, 1) fade-out;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

header {
  margin-bottom: 5px;
}

header .brand-logo {
  font-size: 1.5rem;
}

#loading-bar {
  visibility: hidden;
}

#footer {
  padding-top: 0;
}

#footer a {
  border-bottom: 1px solid;
}

.progress {
  margin: 0;
}

.collection {
  margin-bottom: 0;
}

.collection .collection-item {
  margin-top: 5px;
  margin-bottom: 5px;
}

.row {
  margin-bottom: 10px;
}

body {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

main {
  flex: 1 0 auto;
}

/*# sourceMappingURL=index.cbf096ef.css.map */
