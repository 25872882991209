@charset 'UTF-8';

// Mixins
// @import '~materialize-css/sass/components/prefixer';
// @import '~materialize-css/sass/components/mixins';
@import '~materialize-css/sass/components/color';

// Variables
@import '~materialize-css/sass/components/variables';

// Reset
@import '~materialize-css/sass/components/normalize';

// Components
@import '~materialize-css/sass/components/global';
// @import '~materialize-css/sass/components/icons-material-design';
@import '~materialize-css/sass/components/grid';
@import '~materialize-css/sass/components/navbar';
// @import '~materialize-css/sass/components/roboto';
@import '~materialize-css/sass/components/typography';
@import '~materialize-css/sass/components/cards';
// @import '~materialize-css/sass/components/toast';
// @import '~materialize-css/sass/components/tabs';
// @import '~materialize-css/sass/components/tooltip';
// @import '~materialize-css/sass/components/buttons';
// @import '~materialize-css/sass/components/dropdown';
// @import '~materialize-css/sass/components/waves';
// @import '~materialize-css/sass/components/modal';
// @import '~materialize-css/sass/components/collapsible';
// @import '~materialize-css/sass/components/chips';
// @import '~materialize-css/sass/components/materialbox';
// @import '~materialize-css/sass/components/forms/forms';
@import '~materialize-css/sass/components/forms/switches';
// @import '~materialize-css/sass/components/table_of_contents';
// @import '~materialize-css/sass/components/sideNav';
@import '~materialize-css/sass/components/preloader';
// @import '~materialize-css/sass/components/slider';
// @import '~materialize-css/sass/components/carousel';
// @import '~materialize-css/sass/components/date_picker/default';
// @import '~materialize-css/sass/components/date_picker/default.date';
// @import '~materialize-css/sass/components/date_picker/default.time';

header {
    margin-bottom: 5px;

    .brand-logo {
        font-size: 1.5rem;
    }
}

#loading-bar {
    visibility: hidden;
}

#footer {
    padding-top: 0;

    a {
        border-bottom: 1px solid;
    }
}

.progress {
    margin: 0;
}

.collection {
    margin-bottom: 0;

    .collection-item {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}

.row {
    margin-bottom: 10px;
}

// Make the footer sticky
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

// Make the footer sticky
main {
    flex: 1 0 auto;
}
